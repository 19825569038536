<template>
    <section>
        <div class="bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6">
                <h2
                    id="applicant-information-title"
                    class="text-lg leading-6 font-medium text-blue-500"
                >
                    {{ $t('post.page.information.contextual') }}
                </h2>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    {{ $t('post.page.information.detail') }}
                </p>
            </div>
            <div>
                <div v-for="answer in post.answers" :key="answer.id">
                    <div
                        class="border-t border-gray-200 px-4 py-5 sm:px-6 divide-y divide-gray-200"
                        v-if="
                            isValueEmpty(answer.value) &&
                            isContentOrTitle(answer.config) &&
                            answer.hidden !== 1
                        "
                    >
                        <article class="py-5 px-4">
                            <component
                                :medias="post.medias"
                                :is="answer.name"
                                :value="answer"
                                :post="post"
                            />
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex'
import MapPostCard from '@/components/Model/Map/MapPostCard'
import ClassicAttribute from './Attribute/ClassicAttribute'
import CategoryAttribute from './Attribute/CategoryAttribute'
import FileUploadAttribute from './Attribute/FileUploadAttribute'
import CheckboxAttribute from './Attribute/CheckboxAttribute'

export default {
    name: 'PostAnswers',
    components: {
        location: MapPostCard,
        category: CategoryAttribute,
        gdpr: CheckboxAttribute,
        smalltext: ClassicAttribute,
        email: ClassicAttribute,
        markdown: ClassicAttribute,
        'file-upload': FileUploadAttribute,
        rating: ClassicAttribute,
        'select-field': ClassicAttribute,
        checkbox: CheckboxAttribute,
        radio: CheckboxAttribute,
        date: ClassicAttribute,
        datetime: ClassicAttribute,
        number: ClassicAttribute
    },
    computed: {
        ...mapState('post', {
            post: (state) => state.post,
            postHistories: (state) => state.postHistories
        })
    },
    methods: {
        isValueEmpty(value) {
            if (value) {
                if (typeof value === 'string') {
                    return value !== ''
                }

                if (typeof value === 'object') {
                    return value.length > 0 || Object.keys(value).length > 0
                }
            } else {
                return false
            }
        },
        isContentOrTitle(config) {
            return !(
                JSON.parse(config).as == 'title' ||
                JSON.parse(config).as == 'content'
            )
        }
    }
}
</script>

<style scoped></style>
