<template>
    <!-- Echanges avec le citoyen -->
    <section aria-labelledby="repondre-title">
        <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div class="divide-y divide-gray-200">
                <div class="px-4 py-5 sm:px-6">
                    <h2
                        id="repondre-title"
                        class="text-lg font-medium text-blue-500"
                    >
                        {{ $t('post.page.messages.exchange') }}
                        <span
                            class="inline-flex items-center px-3 rounded-full text-sm font-medium bg-blue-100 text-blue-800 py-0.5"
                            >{{ post.messages.length }}</span
                        >
                    </h2>
                </div>
                <div class="px-4 py-5 w-full 2xl:w-8/9 mx-auto">
                    <div v-if="post.messages && post.messages.length > 0">
                        <div id="messages" class="flex flex-col space-y-2">
                            <div
                                v-for="message in post.messages"
                                :key="message.id"
                                class="chat-message"
                            >
                                <!-- Si celui qui commente est le createur du ticket -->
                                <div
                                    v-if="
                                        (message.author_type === 2 || message.author_type === 4) &&
                                        displayMessage(message)
                                    "
                                    class="flex items-end justify-content-end"
                                >
                                    <div
                                        class="flex flex-col space-y-2 w-10/12 mx-2 order-1 items-end"
                                    >
                                        <span
                                            class="text-xs text-gray-400 italic"
                                        >
                                            <p v-if="message.author">
                                                {{ message.author.name }},
                                            </p>
                                            <p v-else>
                                                {{
                                                    $t(
                                                        'post.page.messages.anonymous'
                                                    )
                                                }},
                                            </p>
                                            <time
                                                :datetime="message.created_at"
                                            >
                                                {{
                                                    moment(message.created_at)
                                                        .locale(
                                                            $store.state.lang
                                                        )
                                                        .format('LLL')
                                                }}
                                            </time>
                                        </span>
                                        <!-- message gestionnaire -->
                                        <div
                                            v-if="message.content"
                                            class="w-full px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300"
                                        >
                                            <span
                                                class="text-white break-words"
                                                v-if="
                                                    message.content.message &&
                                                    message.content.message
                                                        .length > 0
                                                "
                                                v-html="
                                                    displayHtml(
                                                        message.content.message
                                                    )
                                                "
                                            ></span>
                                            <span v-if="message.content.files">
                                                <span
                                                    v-for="(
                                                        file, index
                                                    ) in message.content.files"
                                                    :key="index"
                                                    class="is-flex is-align-items-center bg-gray-300 text-gray-600"
                                                >
                                                    <IconPaperClip
                                                        class="flex-shrink-0 h-5 w-5"
                                                    />
                                                    <a
                                                        :href="file.url"
                                                        :download="file.name"
                                                        class="pl-2 font-medium administrator"
                                                    >
                                                        <p>
                                                            {{ file.name }}
                                                        </p></a
                                                    >
                                                </span>
                                            </span>
                                        </div>
                                        <!-- pieces jointes -->
                                        <div
                                            class="border-t border-gray-200 py-2"
                                            v-if="message.files"
                                        >
                                            <dl
                                                class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2"
                                            >
                                                <div class="sm:col-span-2">
                                                    <dd
                                                        v-for="file in message.files"
                                                        :key="file.id"
                                                        class="mt-1 text-sm text-gray-900"
                                                    >
                                                        <UploadedFile
                                                            :file="file"
                                                        ></UploadedFile>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <IconUserPlain class="h-10 w-10" />
                                    </div>
                                </div>

                                <!-- Sinon, le reste des utilisateurs -->
                                <div
                                    v-else-if="
                                         (message.author_type!== 2 || message.author_type !== 4) &&
                                        displayMessage(message)
                                    "
                                    class="flex justify-content-start"
                                >
                                    <div
                                        class="flex flex-col space-y-2 w-10/12 mx-2 order-1 items-start"
                                    >
                                        <span
                                            class="text-xs text-gray-400 italic"
                                        >
                                            <p v-if="message.author">
                                                {{ message.author.name }},
                                            </p>
                                            <p v-else>
                                                {{
                                                    $t(
                                                        'post.page.messages.anonymous'
                                                    )
                                                }},
                                            </p>
                                            <time
                                                :datetime="message.created_at"
                                            >
                                                {{
                                                    moment(message.created_at)
                                                        .locale(
                                                            $store.state.lang
                                                        )
                                                        .format('LLL')
                                                }}
                                            </time>
                                        </span>
                                        <!-- message citoyen -->
                                        <div
                                            v-if="message.content"
                                            class="w-full px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white"
                                        >
                                            <span
                                                class="break-words"
                                                v-if="message.content.message"
                                                id="message"
                                                v-html="
                                                    displayHtml(
                                                        message.content.message
                                                    )
                                                "
                                            ></span>
                                            <span v-if="message.content.files">
                                                <span
                                                    v-for="(
                                                        file, index
                                                    ) in message.content.files"
                                                    :key="index"
                                                    class="is-flex is-align-items-center"
                                                >
                                                    <IconPaperClip
                                                        class="flex-shrink-0 h-5 w-5"
                                                    />
                                                    <a
                                                        :href="file.url"
                                                        :download="file.name"
                                                        class="pl-2 font-medium administrator"
                                                    >
                                                        <p>
                                                            {{ file.name }}
                                                        </p></a
                                                    >
                                                </span>
                                            </span>
                                        </div>
                                        <!-- pieces jointes -->
                                        <div
                                            class="border-t border-gray-200 py-2"
                                            v-if="message.files"
                                        >
                                            <dl
                                                class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2"
                                            >
                                                <div class="sm:col-span-2">
                                                    <dd
                                                        v-for="file in message.files"
                                                        :key="file.id"
                                                        class="mt-1 text-sm text-gray-900"
                                                    >
                                                        <UploadedFile
                                                            :file="file"
                                                        ></UploadedFile>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div
                                            class="flex flex-col space-y-2 max-w-md order-1 items-end"
                                        >
                                            <p
                                                class="text-xs text-gray-600 italic"
                                            >
                                                <time
                                                    v-if="message.read_at"
                                                    :datetime="message.read_at"
                                                >
                                                    {{
                                                        $t(
                                                            'post.page.messages.read'
                                                        )
                                                    }}

                                                    {{
                                                        moment(message.read_at)
                                                            .locale(
                                                                $store.state
                                                                    .lang
                                                            )
                                                            .format('LLL')
                                                    }}
                                                </time>
                                                <span v-else>{{
                                                    $t(
                                                        'post.page.messages.not_read'
                                                    )
                                                }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="bg-yellow-50 p-4 sm:rounded-lg">
                        <div class="flex align-items-center">
                            <div class="flex-shrink-0" >
                                <IconWarning class="h-5 w-5 text-yellow-400"  />
                            </div>
                            <div class="ml-3">
                                <p class="text-sm text-yellow-700">
                                    {{ $t('post.page.messages.no_exchange') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="bg-gray-50 px-4 py-4 sm:px-6 animate__animated animate__faster animate__zoomIn"
            >
                <div class="min-w-0">
                    <div
                        id="ckComments"
                        class="panel-content p-0 border-bottom border-top border-faded"
                    >
                        <ckeditor
                            v-model="content"
                            class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                            :config="editorConfig"
                            :editor="editor"
                            @input="onChange()"
                        >
                        </ckeditor>
                    </div>
                    <div class="mt-3 flex items-center justify-between">
                        <button
                            id="postComment"
                            :disabled="isDisabled"
                            :class="isDisabled ? 'disabled' : ''"
                            v-if="!loading"
                            class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            type="button"
                            @click.prevent="postComment"
                        >
                            <span class="hidden sm:flex">{{
                                $t('post.page.messages.send')
                            }}</span>
                            <i
                                class="sm:hidden fal fa-paper-plane text-white"
                                aria-hidden="true"
                            ></i>
                        </button>
                        <button
                            v-else
                            class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            disabled="disabled"
                        >
                            <span
                                aria-hidden="true"
                                class="spinner-border spinner-border-sm"
                                role="status"
                            ></span>
                            {{ $t('post.page.messages.process') }}
                        </button>
                        <b-field class="file" v-if="!loading">
                            <b-upload
                                id="uploadFiles"
                                multiple
                                drag-drop
                                @input="showFiles"
                                v-model="dropFiles"
                                class="file-label inline-flex items-center justify-center"
                            >
                                <span
                                    class="file-cta inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    <IconUpload
                                        class="flex-shrink-0 h-5 w-5 sm:mr-3 text-white"
                                    ></IconUpload>
                                    <span class="hidden sm:flex file-label">{{
                                        $t('post.page.messages.add_attachments')
                                    }}</span>
                                </span>
                                <span
                                    class="file-name inline-flex justify-center px-4 py-2 text-sm"
                                    v-if="filesList.length > 0"
                                >
                                    {{ filesList.length }}
                                    <IconPaperClip
                                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                                    />
                                </span>
                            </b-upload>
                        </b-field>
                    </div>
                </div>
                <PostTemplate @change-content="setContent" />
            </div>
        </div>
    </section>
</template>

<style>
#message ul,
#message ul li {
    list-style-type: disc;
}
#message ol,
#message ol li {
    list-style-type: number;
}

#message ol,
#message ul {
    padding-left: 15px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .long-url {
        max-width: 20em !important;
        width: 20em !important;
    }
}

.long-url {
    display: block !important;
    max-width: 16rem;
    width: 16rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>

<style scoped>
#ckComments .ck.ck-toolbar {
    border: none;
    border-bottom: 1px solid #dee2e6 !important;
}

#ckComments .ck.ck-editor__main > .ck-editor__editable {
    border: none;
    padding: 1em 2em 1em 2em;
}

::v-deep .citizen {
    color: black;
}

::v-deep .administrator {
    color: white;
}

::v-deep .upload .upload-draggable {
    padding: 0px;
    padding-right: 3px;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

::v-deep .upload .file-cta:hover {
    background-color: rgb(14, 116, 144);
}
</style>

<script>
import { mapActions } from 'vuex'
import Panel from '../../UI/Panel'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import UploadedFile from '@/components/Model/File/UploadedFile'
import UploadAdapter from '@/components/Model/File/UploadAdapter'
import IconUserPlain from '@/components/icons/iconUserPlain.vue'
import IconPaperClip from '@/components/icons/iconPaperClip.vue'
import IconWarning from '@/components/icons/iconWarning.vue'
import IconUpload from '@/components/icons/iconUpload.vue'
import PostTemplate from '@/components/Model/Post/PostTemplate'

export default {
    name: 'PostMessages',
    props: ['post'],
    components: {
        UploadedFile,
        Panel,
        IconUserPlain,
        IconPaperClip,
        IconWarning,
        IconUpload,
        PostTemplate
    },
    data() {
        return {
            isMessage: true,
            content: null,
            loading: false,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'bold',
                        'italic',
                        'link',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'undo',
                        'redo',
                        '|'
                    ]
                }
            },
            filesList: [],
            dropFiles: []
        }
    },
    computed: {
        isDisabled() {
            if (
                (this.content != null && this.content != '') ||
                (this.filesList != [] && this.filesList.length > 0)
            ) {
                return false
            }
            return true
        }
    },
    created() {},
    methods: {
        onChange() {
            if (
                this.content.includes('&lt;img') &&
                this.content.includes('&gt;')
            ) {
                let images = this.content.match(/&lt;img.*?&gt;/g)
                for (let i = 0; i < images.length; i++) {
                    this.content = this.content.replace(
                        images[i],
                        this.displayHtml(images[i])
                    )
                    const changeContent = new Promise((resolve, reject) => {
                        this.content = this.content.replace(
                            images[i],
                            this.displayHtml(images[i])
                        )
                        resolve()
                    })

                    changeContent.finally(() => {
                        const src = this.content.match(/src="(.*?)"/)[1]
                        const style = this.content.match(/style="(.*?)"/)
                            ? this.content.match(/style="(.*?)"/)[1]
                            : ''
                        const width = this.content.match(/width="(.*?)"/)
                            ? this.content.match(/width="(.*?)"/)[1]
                            : ''
                        const height = this.content.match(/height=(.*?)"/)
                            ? this.content.match(/height=(.*?)"/)[1]
                            : ''
                        let image = document.querySelector(
                            'img[src="' + src + '"]'
                        )
                        image.setAttribute(
                            'style',
                            style + ` width: ${width}; ` + `height: ${height};`
                        )
                    })
                }
            }
            if (
                this.content.includes('&lt;a') &&
                this.content.includes('&lt;/a&gt;')
            ) {
                let links = this.content.match(/&lt;a.*?&lt;\/a&gt;/g)
                for (let i = 0; i < links.length; i++) {
                    this.content = this.content.replace(
                        links[i],
                        this.displayHtml(links[i])
                    )
                }
            }
        },
        displayHtml(message) {
            return message
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&amp;/g, '&')
                .replace(/<br\s*[\/]?>/gi, '')
        },

        displayMessage(message) {
            if (message.content.message) {
                if (message.content.message.length > 0) {
                    return true
                }
            } else if (message.content.files) {
                if (message.content.files.length > 0) {
                    return true
                }
            }
            return false
        },
        ...mapActions('post', ['sendNewMessage', 'getPostHistories']),
        setContent(payload) {
            this.content = payload.content
        },
        onEditorReady(editor) {
            this.uploader(editor)
        },
        uploader(editor) {
            var token = window.localStorage.getItem('token')
            var baseURL = window.localStorage.getItem('baseURL')

            editor.plugins.get('FileRepository').createUploadAdapter = (
                loader
            ) => {
                return new UploadAdapter(loader, token, baseURL)
            }
        },
        postComment() {
            this.loading = true
            if (this.filesList.length == 0) {
                this.sendMessage({ message: this.content })
            } else if (this.filesList.length > 0 && this.content == null) {
                this.sendMessage({
                    files: this.filesList
                })
            } else {
                this.sendMessage({
                    message: this.content,
                    files: this.filesList
                })
            }
            this.filesList = []
            this.dropFiles = []
        },
        sendMessage(content) {
            if (
                (this.content == '' || this.content == null) &&
                this.filesList == []
            ) {
                this.$store.dispatch('toast', {
                    type: 'error',
                    title: this.$t('error.title'),
                    message: this.$t('error.validation.not_empty')
                })
                this.loading = false
            } else {
                if (
                    this.content !== '' &&
                    this.content != null &&
                    this.content.match(/<a(.*?)<\/a>/)
                ) {
                    const regex = /<a href=".+">.{20,}<\/a>/g
                    content.message = content.message.replace(
                        regex,
                        (match) => {
                            return match.replace(/<a /, '<a class="long-url" ')
                        }
                    )
                }
                this.sendNewMessage(content)
                    .then((response) => {
                        let message = response.data.message
                        message.author = {
                            name: this.$store.state.auth.user.name
                        }
                        if (
                            content.hasOwnProperty('message') &&
                            content.hasOwnProperty('files')
                        ) {
                            let messageContent = {
                                content: content.message,
                                created_at: response.data.message.created_at,
                                author: {
                                    name: this.$store.state.auth.user.name
                                }
                            }
                            this.$store.state.post.post.messages.push(
                                messageContent
                            )
                            this.$store.state.post.post.messages.push(
                                response.data.message
                            )
                        } else {
                            this.$store.state.post.post.messages.push(
                                response.data.message
                            )
                        }
                        this.content = ''
                        this.getPostHistories(this.post.id)
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: this.$t('success.title')
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$store.dispatch('dispatchError', error)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        showFiles(dropFiles) {
            dropFiles.forEach((file) => {
                this.encodeFileToBase64(file)
            })
        },
        encodeFileToBase64(file) {
            this.filesList = []
            var fr = new FileReader()
            fr.onload = (e) => {
                this.filesList.push({
                    name: file.name,
                    encodedFile: e.target.result
                })
            }
            fr.readAsDataURL(file)
        }
    }
}
</script>
