<template>
  <!-- Notes internes-->
  <section aria-labelledby='notes-title'>
    <div class='bg-white shadow sm:rounded-lg sm:overflow-hidden'>
      <div class='divide-y divide-gray-200'>
        <div class="px-4 py-5 sm:px-6">
          <h2
            id="notes-title"
            class="text-lg font-medium text-blue-500"
          >
            {{ $t('post.page.comments.internal_note') }}
            <span
              class="inline-flex items-center px-3 rounded-full text-sm font-medium bg-blue-100 text-blue-800 py-0.5"
            >{{ post.comments.length }}</span
            >
          </h2>
        </div>
        <div class="px-4 py-6 sm:px-6" >
          <ul
            v-if='post.comments && post.comments.length > 0'
            role='list'
            class='space-y-8'
          >
            <li v-for='comment in post.comments'>
              <div class='flex space-x-3'>
                <div class='flex-shrink-0'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    class='h-10 w-10'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z'
                      clip-rule='evenodd'
                    />
                  </svg>
                </div>
                <div class='flex flex-col w-11/12'>
                  <div class='text-sm'>
                    <a
                      href='#'
                      class='font-medium text-gray-900'
                    >{{ comment.user.name }}</a
                    >
                  </div>
                  <div
                    class='w-full mt-1 px-4 py-2 rounded-lg inline-block text-sm text-gray-500'
                    v-if='comment.message'
                  >
                                        <span
                                          id='comments'
                                          class='break-words'
                                          v-if='comment.message.message'
                                          v-html='
                                                displayHtml(
                                                    comment.message.message
                                                )
                                            '
                                          style='white-space: pre-line'
                                        >
                                        </span>
                    <span v-if='comment.message.files'>
                                            <div
                                              v-for='(file, index) in comment
                                                    .message.files'
                                              :key='index'
                                              class='flex align-items-center'
                                            >
                                                <IconPaperClip
                                                  class='flex-shrink-0 h-5 w-5'
                                                />
                                                <a
                                                  :href='file.url'
                                                  :download='file.name'
                                                  class='pl-2 font-medium administrator'
                                                >
                                                    <p>{{ file.name }}</p></a
                                                >
                                            </div>
                                        </span>
                  </div>
                  <div class='mt-2 text-sm space-x-2'>
                                        <span class='text-blue-500 font-medium'
                                        >{{ $t('post.page.comments.the') }}
                                            {{
                                            moment(comment.created_at)
                                              .locale($store.state.lang)
                                              .format('L')
                                          }}
                                            {{ $t('post.page.comments.at') }}
                                            {{
                                            moment(comment.created_at)
                                              .locale($store.state.lang)
                                              .format('HH[h]mm')
                                          }}</span
                                        >
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class='bg-yellow-50 p-4 sm:rounded-lg'>
            <div class='flex align-items-center'>
              <div class='flex-shrink-0' >
                <!-- Heroicon name: solid/exclamation -->
                <svg
                  class='h-5 w-5 text-yellow-400'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                  aria-hidden='true'
                >
                  <path
                    fill-rule='evenodd'
                    d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
                    clip-rule='evenodd'
                  />
                </svg>
              </div>
              <div class='ml-3'>
                <p class='text-sm text-yellow-700'>
                  {{ $t('post.page.comments.no_comment') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='bg-gray-50 px-4 py-6 sm:px-6' >
        <div class='hidden sm:flex flex-shrink-0'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            class='h-10 w-10'
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fill-rule='evenodd'
              d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z'
              clip-rule='evenodd'
            />
          </svg>
        </div>
        <div class='min-w-0 flex-1'>
          <div
            id='ckComments'
            class='panel-content p-0 border-bottom border-top border-faded'
          >
            <ckeditor
              v-model='content'
              class='shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md'
              :config='editorConfig'
              :editor='editor'
              @input='onChange()'
            >
            </ckeditor>
          </div>
          <div class='mt-3 flex items-center justify-between'>
            <button
              :disabled='isDisabled'
              :class="isDisabled ? 'disabled' : ''"
              v-if='!loading'
              class='inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              type='button'
              @click.prevent='postComment'
            >
                            <span class='hidden sm:flex'>{{
                                $t('post.page.comments.write')
                              }}</span>
              <i
                class='sm:hidden fal fa-paper-plane text-white'
                aria-hidden='true'
              ></i>
            </button>
            <button
              v-else
              class='inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              disabled='disabled'
            >
                            <span
                              aria-hidden='true'
                              class='spinner-border spinner-border-sm'
                              role='status'
                            ></span>
              {{ $t('post.page.comments.process') }}
            </button>
            <b-field class='file' v-if='!loading'>
              <b-upload
                multiple
                drag-drop
                @input='showFiles'
                v-model='dropFiles'
                class='file-label inline-flex items-center justify-center'
              >
                                <span
                                  class='file-cta inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                >
                                    <IconUpload
                                      class='flex-shrink-0 h-5 w-5 sm:mr-3 text-white'
                                    ></IconUpload>
                                    <span class='hidden sm:flex file-label'>{{
                                        $t('post.page.messages.add_attachments')
                                      }}</span>
                                </span>
                <span
                  class='file-name inline-flex justify-center px-4 py-2 text-sm'
                  v-if='filesList.length > 0'
                >
                                    {{ filesList.length }}
                                    <IconPaperClip
                                      class='flex-shrink-0 h-5 w-5 text-gray-400'
                                    />
                                </span>
              </b-upload>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
#comments > ul li,
#comments > ul {
  list-style-type: disc !important;
}

#comments > ul {
  padding-left: 15px !important;
}

#comments > ol li,
#comments > ol {
  list-style-type: number !important;
}

#comments > ol {
  padding-left: 15px !important;
}
</style>
<style scoped>
#ckComments .ck.ck-toolbar {
  border: none;
  border-bottom: 1px solid #dee2e6 !important;
}

#ckComments .ck.ck-editor__main > .ck-editor__editable {
  border: none;
  padding: 1em 2em 1em 2em;
}

::v-deep .upload .upload-draggable {
  padding: 0px;
  padding-right: 3px;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .upload .file-cta:hover {
  background-color: rgb(14, 116, 144);
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import Panel from '../../UI/Panel'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import UploadedFile from '@/components/Model/File/UploadedFile'
import UploadAdapter from '@/components/Model/File/UploadAdapter'

export default {
  name: 'PostComments',
  components: {
    UploadedFile,
    Panel
  },
  computed: {
    ...mapState('post', {
      post: (state) => state.post
    }),
    isDisabled() {
      if (
        (this.content != null && this.content != '') ||
        (this.filesList != [] && this.filesList.length > 0)
      ) {
        return false
      }
      return true
    }
  },
  data() {
    return {
      content: null,
      loading: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo',
            '|'
          ]
        }
      },
      filesList: [],
      dropFiles: []
    }
  },
  created() {
  },
  methods: {
    ...mapActions('post', ['sendNewComment', 'getPostHistories']),
    onEditorReady(editor) {
      this.uploader(editor)
    },
    onChange() {
      if (
        this.content.includes('&lt;img') &&
        this.content.includes('&gt;')
      ) {
        let images = this.content.match(/&lt;img.*?&gt;/g)
        for (let i = 0; i < images.length; i++) {
          this.content = this.content.replace(
            images[i],
            this.displayHtml(images[i])
          )
          const changeContent = new Promise((resolve, reject) => {
            this.content = this.content.replace(
              images[i],
              this.displayHtml(images[i])
            )
            resolve()
          })

          changeContent.finally(() => {
            const src = this.content.match(/src="(.*?)"/)[1]
            const style = this.content.match(/style="(.*?)"/)
              ? this.content.match(/style="(.*?)"/)[1]
              : ''
            const width = this.content.match(/width="(.*?)"/)
              ? this.content.match(/width="(.*?)"/)[1]
              : ''
            const height = this.content.match(/height=(.*?)"/)
              ? this.content.match(/height=(.*?)"/)[1]
              : ''
            let image = document.querySelector(
              'img[src="' + src + '"]'
            )
            image.setAttribute(
              'style',
              style + ` width: ${width}; ` + `height: ${height};`
            )
          })
        }
      }
      if (
        this.content.includes('&lt;a') &&
        this.content.includes('&lt;/a&gt;')
      ) {
        let links = this.content.match(/&lt;a.*?&lt;\/a&gt;/g)
        for (let i = 0; i < links.length; i++) {
          this.content = this.content.replace(
            links[i],
            this.displayHtml(links[i])
          )
        }
      }
    },
    uploader(editor) {
      var token = window.localStorage.getItem('token')
      var baseURL = window.localStorage.getItem('baseURL')

      editor.plugins.get('FileRepository').createUploadAdapter = (
        loader
      ) => {
        return new UploadAdapter(loader, token, baseURL)
      }
    },
    postComment() {
      this.loading = true
      if (this.filesList.length == 0) {
        this.sendMessage({ message: this.content })
      } else if (
        this.filesList.length > 0 &&
        (this.content == null || this.content == '')
      ) {
        this.sendMessage({
          files: this.filesList
        })
      } else {
        this.sendMessage({
          message: this.content,
          files: this.filesList
        })
      }
      this.filesList = []
      this.dropFiles = []
    },
    displayHtml(message) {
      return message
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/<br\s*[\/]?>/gi, '')
    },
    sendMessage(content) {
      this.loading = true
      if (
        (this.content == '' || this.content == null) &&
        this.filesList == []
      ) {
        this.$store.dispatch('toast', {
          type: 'error',
          title: this.$t('error.title'),
          message: this.$t('error.validation.not_empty')
        })
        this.loading = false
      } else {
        if (
          this.content !== '' &&
          this.content != null &&
          this.content.match(/<a(.*?)<\/a>/)
        ) {
          const regex = /<a href=".+">.{20,}<\/a>/g
          content.message = content.message.replace(
            regex,
            (match) => {
              return match.replace(/<a /, '<a class="long-url" ')
            }
          )
        }
        this.sendNewComment(content)
          .then((response) => {
            this.getPostHistories(this.post.id)
            this.post.comments.push(response.data.comment)
            this.content = ''
            this.$store.dispatch('toast', {
              type: 'success',
              title: 'Sauvegarde effectuée'
            })
          })
          .catch((error) => {
            this.$store.dispatch('dispatchError', error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    showFiles(dropFiles) {
      dropFiles.forEach((file) => {
        this.encodeFileToBase64(file)
      })
    },
    encodeFileToBase64(file) {
      this.filesList = []
      var fr = new FileReader()
      fr.onload = (e) => {
        this.filesList.push({
          name: file.name,
          encodedFile: e.target.result
        })
      }
      fr.readAsDataURL(file)
    }
  }
}
</script>
