<template>
  <main
    v-if='!isLoading'
    id='js-page-content'
    class='page-content'
    role='main'
  >
    <PostDetailsHeader
      @export-pdf='exportPDF()'
      :post='post'
      :loadingDownloadPdf="isPostExport"
    />

    <div
      class='mt-8 max-w-3xl mx-10 grid grid-cols-1 gap-6 sm:px-6 xl:max-w-7xl xl:grid-flow-col-dense xl:grid-cols-3'
    >
      <div class='space-y-6 lg:col-start-1 lg:col-span-2'>
        <PostOpenedBy :post='post' />
        <PostInfo :post='post' />
        <PostAnswers />

        <PostMessages
          :post='post'
          id='messages'
        />
        <PostComments id='commentaires' />
      </div>

      <section
        aria-labelledby='timeline-title'
        class='xl:col-start-3 xl:col-span-1'
      >
        <PostAdmin :post='post' />
        <PostUsers @get-post-data='getData' />
        <PostHistories id='histories' />
      </section>
    </div>
    <div v-if='isPostExport' id='postExport'>
      <div id='mapImgDiv'></div>
    </div>
  </main>
  <b-loading
    v-else
    :is-full-page='false'
    v-model='isLoading'
    :can-cancel='true'
  ></b-loading>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PostComments from '@/components/Model/Post/PostComments'
import PostMessages from '@/components/Model/Post/PostMessages'
import PostAnswers from '@/components/Model/Post/PostAnswers'
import PostHistories from '@/components/Model/Post/PostHistories'
import PostUsers from '@/components/Model/Post/PostUsers'
import PostDetailsHeader from '@/components/Model/Post/PostDetailsHeader'
import PostOpenedBy from '@/components/Model/Post/PostOpenedBy'
import PostInfo from '@/components/Model/Post/PostInfo'
import PostAdmin from '../../components/Model/Post/PostAdmin'

import domtoimage from 'dom-to-image-more'

export default {
  name: 'Post',
  components: {
    PostHistories,
    PostMessages,
    PostComments,
    PostAnswers,
    PostUsers,
    PostDetailsHeader,
    PostOpenedBy,
    PostInfo,
    PostAdmin
  },
  data: function() {
    return {
      modifStatus: false,
      divReponseCitoyen: false,
      buttonResponseCitoyen: true,
      isComment: true,
      isMessage: false,
      isPhoto: true,
      isModif: false,
      formAttributes: [],
      isLoading: true,
      isPostExport: false,
      baseUrl: this.$store.state.baseURL,
      token: this.$store.state.auth.token,
      filename: ' ',
      content: {}
    }
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapState('post', {
      post: (state) => state.post,
      history: (state) => state.history
    }),
    hasOperator: {
      get() {
        return this.$store.state.post.hasOperator
      },
      set(value) {
        this.$store.commit('post/setHasOperator', value)
      }
    },
    isPostRaciLoaded: {
      get() {
        return this.$store.state.post.isPostRaciLoaded
      },
      set(value) {
        this.$store.commit('post/setIsPostRaciLoaded', value)
      }
    }
  },
  methods: {
    ...mapActions('post', ['getPost', 'searchUsersList', 'exportPdfPost']),
    ...mapActions('status', ['getStatus']),
    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    },
    exportPDF() {
      this.isPostExport = true
      var map = document.getElementById('myMap')
      if (map) {
        map.style.height = 'calc(100vh - 500px)'
        this.delay(3000).then(() => {
          domtoimage.toPng(map).then(function(dataUrl) {
            var img = new Image()
            img.src = dataUrl
            img.setAttribute('id', 'mapImg')
            var divImg = document.getElementById('mapImgDiv')
            divImg.appendChild(img)
            map.style.height = 'calc(100vh - 330px)'
          })
          this.exportPDFHandler()
        })
      } else {
        this.exportPDFHandler()
      }
    },
    exportPDFHandler() {
      this.delay(3000).then(() => {
        var divImg = document.getElementById('mapImg')
        var mapView = null
        if (divImg) {
          var pdfView = {
            name: `map_img_post_${this.post.id}`,
            ext: `png`,
            encodedFile: divImg.src
          }
          mapView = { mapImg: pdfView }
        }
        this.exportPdfPost(mapView)
          .then(() => {
            const link = document.createElement('a')
            link.href = `${this.baseUrl}/storage/posts/${this.post.reference}/export/pdf/download?token=${this.token}`
            link.download
            link.click()
          })
          .finally(() => {
            this.isPostExport = false
          })
      })
    },
    getData() {
      let getPostPromise = this.getPost(this.$route.params.id).then(
        () => {
          let racis = Object.assign([], this.post.racis)
          if (racis) {
            let operator = racis.filter(
              (element) => element.is_responsible == 1
            )[0]
            if (operator) {
              this.hasOperator = true
            } else {
              this.hasOperator = false
            }
            racis.sort((a, b) => {
              if (a.is_responsible) {
                this.hasOperator = true
                return -1
              }
              if (b.is_responsible) {
                this.hasOperator = true
                return 1
              }
              return 0
            })
            this.isPostRaciLoaded = true
          }
        }
      )
      let getStatusPromise = this.getStatus()
      Promise.all([getPostPromise, getStatusPromise]).then(() => {
        this.isLoading = false
      })
    }
  },

  beforeDestroy() {
    this.$store.commit('post/setPost', null)
    this.$store.commit('post/setHistory', null)
    this.$store.commit('post/setHasOperator', false)
  }
}
</script>

<style>
#postExport {
  display: none;
}

</style>
